<template>
  <form @submit.prevent="updateData" v-show="!formClave" >
    <div class="delete-acount">
      <p class="tp-body-responsive">{{ $t('perfil.txt_en_esta_seccion_encontaras') }}</p>
      <ButtonDangerTexto
          type="button"
          @click="showModalDeleteAccount=true"
      >
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.25 16.25C4.8375 16.25 4.48438 16.1031 4.19063 15.8094C3.89688 15.5156 3.75 15.1625 3.75 14.75V5C3.5375 5 3.35938 4.92813 3.21563 4.78438C3.07188 4.64063 3 4.4625 3 4.25C3 4.0375 3.07188 3.85938 3.21563 3.71563C3.35938 3.57188 3.5375 3.5 3.75 3.5H6.75C6.75 3.2875 6.82188 3.10938 6.96563 2.96563C7.10938 2.82188 7.2875 2.75 7.5 2.75H10.5C10.7125 2.75 10.8906 2.82188 11.0344 2.96563C11.1781 3.10938 11.25 3.2875 11.25 3.5H14.25C14.4625 3.5 14.6406 3.57188 14.7844 3.71563C14.9281 3.85938 15 4.0375 15 4.25C15 4.4625 14.9281 4.64063 14.7844 4.78438C14.6406 4.92813 14.4625 5 14.25 5V14.75C14.25 15.1625 14.1031 15.5156 13.8094 15.8094C13.5156 16.1031 13.1625 16.25 12.75 16.25H5.25ZM12.75 5H5.25V14.75H12.75V5ZM6.75 13.25H8.25V6.5H6.75V13.25ZM9.75 13.25H11.25V6.5H9.75V13.25Z" fill="#D40737"/>
        </svg>
        {{ $t('perfil.txt_eliminar_cuenta') }}
      </ButtonDangerTexto>
    </div>
    <div class="form-perfil__wrapper">
      <div class="form-perfil__account">
        <div class="form-perfil__photo">
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30 30C27.25 30 24.8958 29.0208 22.9375 27.0625C20.9792 25.1042 20 22.75 20 20C20 17.25 20.9792 14.8958 22.9375 12.9375C24.8958 10.9792 27.25 10 30 10C32.75 10 35.1042 10.9792 37.0625 12.9375C39.0208 14.8958 40 17.25 40 20C40 22.75 39.0208 25.1042 37.0625 27.0625C35.1042 29.0208 32.75 30 30 30ZM10 45V43C10 41.5833 10.3646 40.2812 11.0938 39.0938C11.8229 37.9062 12.7917 37 14 36.375C16.5833 35.0833 19.2083 34.1146 21.875 33.4688C24.5417 32.8229 27.25 32.5 30 32.5C32.75 32.5 35.4583 32.8229 38.125 33.4688C40.7917 34.1146 43.4167 35.0833 46 36.375C47.2083 37 48.1771 37.9062 48.9062 39.0938C49.6354 40.2812 50 41.5833 50 43V45C50 46.375 49.5104 47.5521 48.5312 48.5312C47.5521 49.5104 46.375 50 45 50H15C13.625 50 12.4479 49.5104 11.4688 48.5312C10.4896 47.5521 10 46.375 10 45ZM15 45H45V43C45 42.5417 44.8854 42.125 44.6562 41.75C44.4271 41.375 44.125 41.0833 43.75 40.875C41.5 39.75 39.2292 38.9062 36.9375 38.3438C34.6458 37.7812 32.3333 37.5 30 37.5C27.6667 37.5 25.3542 37.7812 23.0625 38.3438C20.7708 38.9062 18.5 39.75 16.25 40.875C15.875 41.0833 15.5729 41.375 15.3438 41.75C15.1146 42.125 15 42.5417 15 43V45ZM30 25C31.375 25 32.5521 24.5104 33.5312 23.5312C34.5104 22.5521 35 21.375 35 20C35 18.625 34.5104 17.4479 33.5312 16.4688C32.5521 15.4896 31.375 15 30 15C28.625 15 27.4479 15.4896 26.4688 16.4688C25.4896 17.4479 25 18.625 25 20C25 21.375 25.4896 22.5521 26.4688 23.5312C27.4479 24.5104 28.625 25 30 25Z" fill="var(--primary)"/>
          </svg>
        </div>
        <StatusBadge
            v-if="user.is_subscribed_membership"
        >
          Miembro Club Manu
        </StatusBadge>
        <p>
          {{`${payload.first_name} ${payload.last_name}`}}
          <svg @click="isEditName = !isEditName" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.75 14.25H4.81875L12.15 6.91875L11.0813 5.85L3.75 13.1813V14.25ZM3 15.75C2.7875 15.75 2.60938 15.6781 2.46563 15.5344C2.32188 15.3906 2.25 15.2125 2.25 15V13.1813C2.25 12.9813 2.2875 12.7906 2.3625 12.6094C2.4375 12.4281 2.54375 12.2688 2.68125 12.1313L12.15 2.68125C12.3 2.54375 12.4656 2.4375 12.6469 2.3625C12.8281 2.2875 13.0188 2.25 13.2188 2.25C13.4188 2.25 13.6125 2.2875 13.8 2.3625C13.9875 2.4375 14.15 2.55 14.2875 2.7L15.3188 3.75C15.4688 3.8875 15.5781 4.05 15.6469 4.2375C15.7156 4.425 15.75 4.6125 15.75 4.8C15.75 5 15.7156 5.19063 15.6469 5.37188C15.5781 5.55313 15.4688 5.71875 15.3188 5.86875L5.86875 15.3188C5.73125 15.4563 5.57188 15.5625 5.39063 15.6375C5.20938 15.7125 5.01875 15.75 4.81875 15.75H3ZM11.6063 6.39375L11.0813 5.85L12.15 6.91875L11.6063 6.39375Z" fill="var(--primary)"/>
          </svg>
        </p>
        <div class="is-edit-name" v-if="isEditName">
          <InputComponent
              :disabled="false"
              :placeholder="$t('placeholder.txt_nombre')"
              :type="'text'"
              :required="true"
              :bg_color="'var(--text-white)'"
              v-model="payload.first_name"
          />
          <InputComponent
              :disabled="false"
              :placeholder="$t('placeholder.txt_apellido')"
              :type="'text'"
              :required="true"
              :bg_color="'var(--text-white)'"
              v-model="payload.last_name"
          />
          <p class="tp-title-responsive">
            {{ user.points }}
            <span class="tp-body-responsive">{{ $t('perfil.txt_puntos') }}</span>
          </p>
        </div>
        <div class="is-edit-name" v-if="!isEditName">
          <div class="form-perfil__compras">
            <p class="tp-title-responsive">
              {{ user.points }}
              <span class="tp-body-responsive">{{ $t('perfil.txt_puntos') }}</span>
            </p>
          </div>
          <button type="button" v-if="!user.is_subscribed_membership" class="button button-membresia">
            <router-link to="/membresia">
              {{ $t('perfil.txt_adquirir_membresia') }}
            </router-link>
          </button>
          <p v-if="!user.is_subscribed_membership">{{ $t('perfil.txt_maximiza_tus_beneficios') }}</p>
        </div>
      </div>
      <div class="form-perfil-datos">
        <div>
          <InputComponent
              :disabled="false"
              :placeholder="$t('placeholder.txt_numero')"
              :type="'tel'"
              :required="true"
              :bg_color="'var(--text-white)'"
              :minlength="'9'"
              :maxlength="'9'"
              v-model="payload.phone"
          >
          </InputComponent>
        </div>
        <div>
          <InputComponent
              :disabled="true"
              :placeholder="$t('placeholder.txt_correo_electronico')"
              :type="'email'"
              :required="false"
              :bg_color="'var(--text-white)'"
              v-model="user.email"
          >
          </InputComponent>
        </div>
        <div class="input-with-icon">
          <div>
            <InputComponent
                class="dni-input"
                :disabled="user.national_id != null && user.national_id !== ''"
                :placeholder="$t('placeholder.txt_dni')"
                :type="'text'"
                :required="false"
                :bg_color="'var(--text-white)'"
                v-model="payload.national_id"
            >
            </InputComponent>
            <span class="tp-body-responsive">{{ $t('perfilmicuentauser.txt_acumula_puntos_en_todos_los_canales') }}</span>
          </div>

          <abbr title="Acumula puntos en todos los canales.">
            <svg @click="showMessageOne" class="message" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.5208 8.90417 12.7125 8.7125C12.9042 8.52083 13 8.28333 13 8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8C11 8.28333 11.0958 8.52083 11.2875 8.7125C11.4792 8.90417 11.7167 9 12 9ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="currentColor"/>
            </svg>
          </abbr>
          <div v-if="messageOneVisible" class="messageTextOne tp-body-responsive">{{ $t('perfilmicuentauser.txt_acumula_puntos_en_todos_los_canales') }}</div>
        </div>
        <div class="input-with-icon">
          <div>
            <InputComponent
                v-if="user.date_birthday != null && user.date_birthday !== ''"
                :disabled="user.date_birthday != null && user.date_birthday !== ''"
                :placeholder="$t('placeholder.txt_fecha_de_nacimiento')"
                :type="'text'"
                :required="false"
                :bg_color="'var(--text-white)'"
                v-model="fixed_birthday"
            >
            </InputComponent>
            <date-picker class="date"
                         v-if="user.date_birthday == null || user.date_birthday === ''"
                         v-model:value="payload.date_birthday"
                         lang="es"
                         type="date"
                         format="DD-MM-YYYY"
                         value-type="YYYY-MM-DD"
                         placeholder="Fecha de nacimiento"
            >
            </date-picker>
            <span class="tp-body-responsive">{{ $t('perfilmicuentauser.txt_adquiere_ofertas_exclusivas') }}</span>
          </div>
          <abbr title="Adquiere ofertas exclusivas por tu día.">
            <svg @click="showMessageTwo" class="message" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.5208 8.90417 12.7125 8.7125C12.9042 8.52083 13 8.28333 13 8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8C11 8.28333 11.0958 8.52083 11.2875 8.7125C11.4792 8.90417 11.7167 9 12 9ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="currentColor"/>
            </svg>
          </abbr>
          <div v-if="messageTwoVisible" class="messageTextTwo tp-body-responsive">{{ $t('perfilmicuentauser.txt_adquiere_ofertas_exclusivas') }}</div>
        </div>
        <div class="select-component">
          <WhiteSelectComponent
              :optionsSelect="genderOptions"
              :bg_color="'white'"
              :name="'selectGenderComponent'"
              :required=true
              :placeholder="$t('placeholder.txt_pronombre')"
              :valueObj="'name'"
              v-model="tempGender"
              @dataFiltered="consoleStuff"
          >
          </WhiteSelectComponent>
        </div>
        <ButtonPrimary
            type="submit"
            :loading="loadingCambiar">
          {{messagePerfil}}
        </ButtonPrimary>
      </div>
    </div>
  </form>
  <form @submit.prevent="resetearClave" class="formulario_clave" v-show="formClave" style="margin-top: 30px">
    <div class="formulario_clave_container">
      <div class="clave__password">
        <PasswordComponent
            :placeholder="$t('placeholder.txt_ingresa_la_contrasena_actual')"
            :required="true"
            :bg_color="'#F7F7F7'"
            :disabled="loadingCambiar"
            v-model="old_password"
        >
        </PasswordComponent>
        <PasswordComponent
            :placeholder="$t('placeholder.txt_ingresa_la_nueva_contasena')"
            :required="true"
            :bg_color="'#F7F7F7'"
            :disabled="loadingCambiar"
            v-model="new_password"
        >
        </PasswordComponent>
        <PasswordComponent
            :placeholder="$t('placeholder.txt_vuelve_a_ingresar_la_nueva_contrasena')"
            :required="true"
            :bg_color="'#F7F7F7'"
            :disabled="loadingCambiar"
            v-model="new_password2"
        >
        </PasswordComponent>
      </div>
      <p>{{ $t('perfilmicuentauser.txt_tu_nueva_contrasena_debe_tener') }}</p>
      <div class="clave__buttons">
        <ButtonSecundary
            type="button"
            @click="formClave=!formClave"
        >
          {{ $t('dropdownaddress.txt_regresar') }}
        </ButtonSecundary>
        <ButtonPrimary
            :loading="loadingCambiar"
            type="submit"
        >
          {{messagePass}}
        </ButtonPrimary>
      </div>
    </div>
  </form>
  <transition name="slide-fade">
    <TwoButtonGenericModal
        v-if="showModalDeleteAccount"
        :title="this.$t('barralateralizquierda.txt_eliminar_mi_cuenta')"
        :content="this.$t('barralateralizquierda.txt_deseas_eliminar_tu_cuenta')"
        :accept="this.$t('barralateralizquierda.txt_si_eliminar')"
        :cancel="this.$t('barralateralizquierda.txt_no_seguir_aqui')"
        @close_modal="showModalDeleteAccount=false"
        @accepted_modal="deleteAccount"
    >
    </TwoButtonGenericModal>
  </transition>
</template>

<script>

import { mapState, mapMutations } from "vuex"
import UserServices from "@/services/user-services"
import User from "@/services/user-services"
import InputComponent from "@/components/new/inputs/InputComponent.vue";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
import moment from "moment";
import ButtonSecundary from "@/components/new/buttons/ButtonSecundary";
import PasswordComponent from "@/components/new/inputs/PasswordComponent.vue";
import WhiteSelectComponent from "@/components/new/inputs/WhiteSelectComponent.vue";
import TwoButtonGenericModal from "@/components/MODALS/TwoButtonGenericModal.vue";
import ButtonDangerTexto from "@/components/new/buttons/ButtonDangerTexto";
import StatusBadge from "@/components/new/buttons/StatusBadge";

export default {
  components: {
    StatusBadge,
    ButtonDangerTexto,
    TwoButtonGenericModal,
    ButtonSecundary,
    ButtonPrimary,
    InputComponent,

    PasswordComponent,
    WhiteSelectComponent
},
  data () {
    return {
      full_name: '',
      payload: {
        first_name: "",
        last_name: "",
        phone: "",
        national_id: "",
        date_birthday: '',
        gender: null,
      },
      isEditName: false,
      showModalDeleteAccount: false,
      tempGender: null,
      formClave:false,
      old_password: '',
      new_password: '',
      new_password2: '',
      loadingCambiar: false,
      fixed_birthday: '',
      genderOptions: [
        {
          name: this.$t('perfilmicuentauser.txt_ella'),
          value: 1},
        {
          name: this.$t('perfilmicuentauser.txt_el'),
          value: 0
        }
      ],
      messageOneVisible: false,
      messageTwoVisible: false,
    }
  },
  computed: {
    ...mapState(['user']),
    messagePass() {
      if (this.loadingCambiar) {
        return this.$t('paymentlzipay.txt_cargando')
      } else {
        return this.$t('buttons.txt_confirmar_contrasena')
      }
    },
    messagePerfil() {
      if (this.loadingCambiar) {
        return this.$t('paymentlzipay.txt_cargando')
      } else {
        return this.$t('buttons.txt_guardar')
      }
    },
  },
  async mounted () {
    this.setupUserData()
    try {
      const response = await UserServices.updateInfoUser();
      if (response.data.success) {
        this.payload.first_name = response.data.data.first_name;
        this.payload.last_name = response.data.data.last_name;
        this.setUser(response.data.data);
      }
    } catch (error) {
      console.error('Error al cargar los datos del usuario:', error);
    }
  },
  methods: {
    async deleteAccount () {
      this.showModalDeleteAccount = false
      let response = await User.deleteAccount()

      if (response.data.success) {
        this.$toast.success(this.$t('perfil.txt_se_elimino_su_cuenta'))
      } else {
        this.$toast.info(this.$t('perfil.txt_su_usuario_ya_se'))
      }
      this.setAuthenticatedFalse()
      localStorage.clear()
      this.setWebVersion(parseInt(process.env.VUE_APP_CURRENT_VERSION))
      await this.$router.push("/")
    },
    ...mapMutations(['setUser', 'setAuthenticatedFalse', 'setWebVersion']),
    consoleStuff() {
      this.payload.gender = this.tempGender.value
      console.log("pay", this.payload)
    },
    setupUserData() {
      this.payload.first_name = this.user.first_name
      this.payload.last_name = this.user.last_name
      this.payload.phone = this.user.phone
      this.payload.national_id = this.user.national_id
      this.payload.gender = this.user.gender
      if (this.user.date_birthday) {
        this.payload.date_birthday = moment(this.user.date_birthday).format('YYYY-MM-DD')
        this.fixed_birthday = moment(this.user.date_birthday).format('DD-MM-YYYY')
      }
      if (this.user.gender != null) {
        if (this.user.gender === 0) {
          this.tempGender = this.genderOptions[1]
        } else {
          this.tempGender = this.genderOptions[0]
        }
      }
    },
    async updateData () {
      let int_array = [8, 12]
      if (this.payload.phone.length !== 9) {
        this.$toast.error(this.$t('toast.txt_numero_telefonico_debe_tener_9_digitos'))
        return
      }
      if (this.payload.national_id !== '' && this.payload.national_id != null) {
        this.payload.national_id = this.payload.national_id.trim()
        if (!int_array.includes(this.payload.national_id.length)) {
          this.$toast.error(this.$t('toast.txt_debe_ingresar_un_dni_o_ce_valido'))
          return
        }
      }
        try {
          this.loadingCambiar=true
          let response = await UserServices.updateInfoUser(this.payload)
          if (response.data.success) {
            console.log("response", response.data.data)
            this.setUser(response.data.data)
            this.$toast.success(this.$t('perfilmicuentauser.txt_se_actualizaron_sus_datos'))
            this.setupUserData()
            this.loadingCambiar=false
            this.isEditName = false
          }
        } catch (error) {
          this.$toast.error(this.$t('perfilmicuentauser.txt_hubo_un_error_al_actualizar'))
          console.log("error al actualizar", error)
          this.loadingCambiar=false
        }
    },
    async resetearClave() {
      if (this.new_password.length < 6) {
        this.$toast.error(this.$t('perfilcambiarclave.txt_la_contrasena_debe_tener'))
        return
      }
      if (this.new_password !== this.new_password2) {
        this.$toast.error(this.$t('perfilmicuentauser.txt_nueva_contrasena_debe_ser'))
        return
      }

      this.loadingCambiar = true
      let response = await User.setPassword({old_password: this.old_password, new_password: this.new_password})

      if(response.data.success) {
        console.log(response.data)
        this.$toast.success(this.$t('perfilcambiarclave.txt_se_cambio_su_contrasena'))
        this.loadingCambiar=false
        this.formClave=false
        return
      }
      console.log(response.data)
      this.$toast.error(response.data.message)
      this.loadingCambiar=false
    },
    showMessageOne() {
      this.messageOneVisible = !this.messageOneVisible;
    },
    showMessageTwo() {
      this.messageTwoVisible = !this.messageTwoVisible;
    },
  }
}
</script>

<style scoped>
.delete-acount{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 20px;
}

.form-perfil__wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.form-perfil__account{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;
  background-color: var(--secundary-50);
}

.is-edit-name{
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 10px;
  width: fit-content;
}

.is-edit-name > p{
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.form-perfil__photo{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
  border-radius: 50%;
  background-color: var(--text-white);
  margin-inline: auto;
}

.form-perfil__photo ~ p{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.form-perfil__photo ~ p svg{
  cursor: pointer;
}

.form-perfil__photo > .badge-base{
  margin-inline: auto;
}

.form-perfil__compras{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.form-perfil__compras p{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.button-membresia{
  width: fit-content;
  margin-inline: auto;
  background-color: var(--secundary);
  border: 1px solid var(--secundary);
}

.button-membresia a{
  color: var(--text-black);
}

.form-perfil-datos{
  display: grid;
  grid-template-columns: 1fr;
  gap: 14px;
}

.input-with-icon{
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.form-perfil-datos button{
  width: fit-content;
}

.input-with-icon > div{
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.input-with-icon > div span{
  margin-left: 4px;
}

.input-with-icon{
  position: relative;
}

.input-with-icon svg{
  display: none;
}

.message{
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.messageTextOne,
.messageTextTwo{
  position: absolute;
  top: -30px;
  right: 30px;
  display: block;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
  z-index: 1000;
}

@media screen and (min-width: 600px){
  .form-perfil-datos{
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  .form-perfil-datos > div{
    display: grid;
    grid-template-columns: 1fr 24px;
    gap: 10px;
  }

  .form-perfil-datos > div .input-container{
    width: 100%;
  }

  .input-with-icon{
    gap: 10px;
  }

  .input-with-icon > div .dni-input{
    width: 100%;
  }

  .input-with-icon > div span{
    display: none;
  }

  .form-perfil-datos button{
    width: fit-content;
  }

  .input-with-icon svg{
    display: block;
  }
}

@media screen and (min-width: 1240px){
  .form-perfil-datos{
    grid-template-columns: repeat(auto-fit, minmax(200px, 290px));
  }

  .form-perfil-datos button:last-child{
    margin-left: auto;
    margin-right: 34px;
  }

  .message{
    cursor: pointer;
  }

  .form-perfil__wrapper{
    flex-direction: row;
    align-items: flex-start;
  }

  .form-perfil__account{
    width: clamp(200px, 100%, 300px);
  }
}
</style>